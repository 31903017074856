global.$ = global.jQuery = require('jquery');
require('jquery-ui');
import 'jquery-ui/ui/widgets/datepicker.js';
import 'jquery-ui/ui/widgets/autocomplete.js';
import './bootstrap';


import '../assets/js/imagesloaded.pkgd.min.js';
import '../assets/js/isotope.pkgd.min.js';
import 'owl.carousel';
import 'jquery-lazy/jquery.lazy.min';
import '../assets/js/jquery.timepicker.min.js';
import '../assets/js/main.js';


$(function($) {
    $("img.lazy").Lazy();
});
